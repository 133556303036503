<template>
  <div class="mt-15 mb-5">
    <v-layout justify-end>
      <!-- <v-btn @click="ir()" color="#466BE3" text rounded class="mb-5">
        <strong> Abrir en una pestaña nueva </strong>
      </v-btn> -->
    </v-layout>

    <iframe
      :width="widthI"
      :height="height"
      :src="infoApp.url"
      frameborder="0"
      style="border: 0"
      allowfullscreen
      sandbox="allow-scripts allow-same-origin"
    ></iframe>
  </div>
</template>
<script>
import Api from "@/utils/api";
export default {
  data() {
    return {
      id: this.$route.params.id,
      url: "",
      widthI: null,
      height: null,
      infoApp: {
        logo: null,
        url: "",
      },
    };
  },
  methods: {
    ir() {
      let newURL = document.createElement("a");
      newURL.id = "id-" + Math.floor(Math.random() * (9999999 - 500) + 500);
      newURL.href = this.infoApp.url;
      newURL.target = "_blank";
      document.body.appendChild(newURL);
      newURL.click();
    },
    getDetailsApp() {
      Api.noAuth()
        .getDetailsApp(this.id)
        .then((resp) => resp.json())
        .then((res) => {
          this.loading = false;
          this.infoApp.logo = res.data[0].logo;
          this.infoApp.url = res.data[0].url;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
  mounted() {
    if (screen.width > 2000) {
      this.widthI = screen.width / 1.8;
      this.height = screen.height / 1.3;
    } else if (screen.width > 1500 && screen.width <= 2000) {
      this.widthI = screen.width / 1.4;
      this.height = screen.height / 1.5;
    } else if (screen.width > 900 && screen.width <= 1500) {
      this.widthI = screen.width / 1.1;
      this.height = screen.height / 1.2;
    } else {
      this.widthI = screen.width;
      this.height = screen.height;
    }
    this.getDetailsApp();
  },
};
</script>
